import React from 'react';
import logo from './logo.svg';
import './App.css';

const Book = [
  {
    title: "Author's Note",
    subtitle: "A Timeless Gift",
    p: [
      `This digital book is a gift for Malak, from someone who had no intention of falling in love but did. This is a forever gift — the story of us — that will remain hosted for anyone to read, or simply as another love story in the grand tapestry of human experience.`,
    ]
  },
  {
    title: "Prologue",
    subtitle: "The First Glimpse",
    p: [
      `[...]`,
      `She was a mystery, a puzzle contained within the screen of my phone, under the name "Malak." At 11:23 AM, she unveiled a piece of herself—a video of her skillfully maneuvering a manual car. I'm a car guy, and this unexpected glimpse into her world made me melt. At 11:24 AM, I took my first leap, responding with a compliment directed at her profile photo, "Cool dress."`,
      `Our digital conversation the previous night had danced on the edge of something more, leaving an unfinished melody in my mind. That Saturday morning, life called; I had apartments to scout, responsibilities that couldn't wait. But there, in a corner of my mind, she remained, a constant pull in a world full of pushes.`,
      `By 11:27 AM, I knew I had to send a message. "Hey, just wanted to let you know I'm not ghosting you. Just busy with apartment hunting." Her reply was almost instantaneous, "Thank you for telling me that." The gratitude in her words felt like a soft sigh of relief, tinged with vulnerability. It was as if her past disappointments were speaking through her, adding another layer of complexity to our budding relationship.`,
      `At 3:56 PM, I found myself caught in a wave of spontaneity. Was it imprudent to ask her out for the same night? Perhaps, but the greatest tales often begin with a reckless act. "Are you up for an adventure tonight?" I texted. Her response arrived at 5:39 PM, after she delved into an afternoon nap, "Yes sure"`,
    ]
  },
  {
    subtitle: "When Virtual Meets Reality",
    p: [
      `As the clock struck eight, I rolled down the quiet street, the dimming sunlight casting its golden farewell while streetlights flickered to life. My heart skipped a beat when I spotted her. She stood there, radiant, dressed in an outfit that was both chic and modest. The streetlight above her lent her an ethereal glow, setting her eyes aflame with something sparkling and indescribable.`,
      `"Malak?" I rolled down the window, my voice tinged with hope and nerves.`,
      `She smiled—a genuine, breathtaking smile—and my world tilted. "That's me," her voice cracked, a charming blend of nervousness and confidence. She was real, and she was here.`,
      `"Hop in," I gestured, my heart pounding like a drumline in my chest.`
    ]
  },
  {
    subtitle: "Moonlight & Waves",
    p: [
      `As she settled into the passenger seat, her presence filled the car like a warm, inviting melody. Every glance we exchanged was electric, foreshadowing the evening ahead. 'You look nervous,' I said as we drove toward our first destination—a hidden eatery.`,
      `I'm really not, I'm comfortable,' she replied. Her voice was soft but tinged with a palpable excitement that couldn't be ignored.`,
      `After grabbing dinner, we headed to a secluded rocky beach, a locale whispered about only among locals. I parked the car in a secluded spot, the encroaching darkness enveloping us as if harboring a secret. Guided only by the narrow beam of my phone's flashlight and the ambient glow of the moon, we walked in silence.`,
      `Finally, we reached the shore where waves caressed the rocks like a lover's touch. I took a seat, my heart pounding in my ears. She sat beside me, close enough for a face-to-face chat while we ate.`,
      `The night was dark, but when our eyes met, it was as if the universe stood still. No words were necessary; the rhythmic roar of the ocean and a sky full of stars spoke for us.`,
    ]
  },
  {
    subtitle: "Cobbled Streets and Future Promises",
    p: [
      `After the beach, our night was far from over. She was staying in an old town, a labyrinth of cobbled streets and mysterious alleys that had witnessed countless tales of love and heartache. Hand in hand, we roamed the deserted lanes, our footsteps echoing on the ancient stones. It was as though time had paused, granting us this stolen moment where only we existed.`,
      `Finally, we returned to the sanctuary of my car, a private universe in a sea of darkness. The atmosphere inside was different now—thicker, charged with the kind of electricity that only blooms after shared laughter and intimate stories. We talked about our lives, our fears, our dreams; each word a building block in a rapidly ascending tower of trust and affection.`,
      `The air between us became heavier with each passing second, filled with the unspoken questions that often lurk in the shadows of new relationships. I looked into her eyes, the same eyes that had sparkled under the streetlights and had been my compass through the night.`,
      `"Want to break the physical barrier?" I asked, my voice tinged with a confidence that made my heart race.`,
      `She smiled—oh, how that smile could stop time—and nodded. She thought I was going to kiss her, but that would have been too easy, too predictable. Instead, I took my hand and placed it palm-up next to hers. "Let's see whose hand is bigger," I said.`,
      `She laughed, a melodious sound that rang through the car, as she placed her palm next to mine. The physical barrier shattered, not with the urgency of a kiss, but with the tenderness of hands touching. She took my hand and began to caress it gently, her touch sending waves of warmth up my arm and straight to my heart.`,
      `As the conversation flowed effortlessly, I reached below the car pedals, where a 3D-printed ring had lain forgotten for weeks. Yet in that moment, it felt as if it had always been waiting for her. "You're too perfect not to marry," I said, half-joking, half-serious, as I slipped the ring onto her finger.`,
      `And as her words hung in the air, one thing became clear: Our night was just one chapter in a book yet to be written, a tale of love and serendipity that neither of us could ever have predicted.`,
      `To be continued...`
    ]
  },
]

function App() {
  return (
    <div className="App">
      <section className="first-section">
        <div className="inner-stroke">
          <p className="book-subtitle">03/09/2022</p>
          <h1 className="book-title">
            <svg width="120" height="99" viewBox="0 0 154 99" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M77.25 97.9887C62.1765 87.3895 31.5435 62.6693 11.3177 36.6855C3.99338 27.276 1.00292 15.4026 1.00292 3.47846V2.26868C1.00292 0.963783 2.70325 0.406228 3.48183 1.4534C20.9392 24.933 58.4404 59.9684 76.5836 71.2356C77.0035 71.4964 77.4965 71.4964 77.9164 71.2356C96.0597 59.9684 133.561 24.933 151.018 1.4534C151.797 0.406228 153.497 0.963784 153.497 2.26868V3.47846C153.497 15.4026 150.507 27.276 143.182 36.6855C122.956 62.6693 92.3235 87.3895 77.25 97.9887C77.1946 97.9499 77.3058 98.0278 77.25 97.9887C77.3054 97.9499 77.1942 98.0278 77.25 97.9887Z" fill="url(#paint0_radial_1_11)" fill-opacity="0.5" />
              <path d="M77.25 97.9887C62.1765 87.3895 31.5435 62.6693 11.3177 36.6855C3.99338 27.276 1.00292 15.4026 1.00292 3.47846V2.26868C1.00292 0.963783 2.70325 0.406228 3.48183 1.4534C20.9392 24.933 58.4404 59.9684 76.5836 71.2356C77.0035 71.4964 77.4965 71.4964 77.9164 71.2356C96.0597 59.9684 133.561 24.933 151.018 1.4534C151.797 0.406228 153.497 0.963784 153.497 2.26868V3.47846C153.497 15.4026 150.507 27.276 143.182 36.6855C122.956 62.6693 92.3235 87.3895 77.25 97.9887ZM77.25 97.9887C77.3058 98.0278 77.1946 97.9499 77.25 97.9887ZM77.25 97.9887C77.1942 98.0278 77.3054 97.9499 77.25 97.9887Z" stroke="url(#paint1_radial_1_11)" />
              <defs>
                <radialGradient id="paint0_radial_1_11" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.0832 98.1056) rotate(-90) scale(153.495 112.154)">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint1_radial_1_11" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.25 48) rotate(90) scale(50 76.2471)">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>
            <br />
            What's Hers, Found Her.</h1>
          <p className="book-author"><i>For Malak, By Him</i></p>
        </div>
      </section>
      {Book.map(section => (
        <section className="second-section">
          {!!section.title ? <h1 className="prologue-title">{section.title}</h1> : null}
          {!!section.subtitle ? <div className="prologue-subtitle"><i>{section.subtitle}</i></div> : null}
          <div className="prologue-content">
            {section.p.map(p => (<p>{p}</p>))}
          </div>
          <hr />
        </section>
      ))}
      <svg width="120" height="99" viewBox="0 0 154 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M77.25 97.9887C62.1765 87.3895 31.5435 62.6693 11.3177 36.6855C3.99338 27.276 1.00292 15.4026 1.00292 3.47846V2.26868C1.00292 0.963783 2.70325 0.406228 3.48183 1.4534C20.9392 24.933 58.4404 59.9684 76.5836 71.2356C77.0035 71.4964 77.4965 71.4964 77.9164 71.2356C96.0597 59.9684 133.561 24.933 151.018 1.4534C151.797 0.406228 153.497 0.963784 153.497 2.26868V3.47846C153.497 15.4026 150.507 27.276 143.182 36.6855C122.956 62.6693 92.3235 87.3895 77.25 97.9887C77.1946 97.9499 77.3058 98.0278 77.25 97.9887C77.3054 97.9499 77.1942 98.0278 77.25 97.9887Z" fill="url(#paint0_radial_1_11)" fill-opacity="0.5" />
        <path d="M77.25 97.9887C62.1765 87.3895 31.5435 62.6693 11.3177 36.6855C3.99338 27.276 1.00292 15.4026 1.00292 3.47846V2.26868C1.00292 0.963783 2.70325 0.406228 3.48183 1.4534C20.9392 24.933 58.4404 59.9684 76.5836 71.2356C77.0035 71.4964 77.4965 71.4964 77.9164 71.2356C96.0597 59.9684 133.561 24.933 151.018 1.4534C151.797 0.406228 153.497 0.963784 153.497 2.26868V3.47846C153.497 15.4026 150.507 27.276 143.182 36.6855C122.956 62.6693 92.3235 87.3895 77.25 97.9887ZM77.25 97.9887C77.3058 98.0278 77.1946 97.9499 77.25 97.9887ZM77.25 97.9887C77.1942 98.0278 77.3054 97.9499 77.25 97.9887Z" stroke="url(#paint1_radial_1_11)" />
        <defs>
          <radialGradient id="paint0_radial_1_11" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.0832 98.1056) rotate(-90) scale(153.495 112.154)">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </radialGradient>
          <radialGradient id="paint1_radial_1_11" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.25 48) rotate(90) scale(50 76.2471)">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default App;
